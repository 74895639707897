
.effybuy_kaizen_main_div{
    position: sticky;
    left: 0;
    top: 0px;
    background-color: white;
    z-index: 10;
    padding: 10px;
}

.effybuy_kaizen_main_div > .effybuy_kaizen_btn_div{
    display: flex;
    width: fit-content;
    border: 3px solid #ffe4cf;
    background-color: #fcf3ec;
    border-radius: 10px;
    box-shadow: inset 0 0 10px #ffe4cf;
}

.effybuy_kaizen_main_div > .effybuy_kaizen_btn_div > .effybuy_kaizen_ind_btn{
    padding: 5px 10px;
    font-weight: bold;
}

.effybuy_kaizen_main_div > .effybuy_kaizen_btn_div > .effybuy_kaizen_ind_act_btn{
    background-color: #ffe4cf;
    color: black;
    border-radius: 10px;
}