.privacy_policy_main_div {
  margin-top: 80px;
}
.privacy_policy_title {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}
.privacy_policy_title_effybuy {
  font-size: 20px;
  text-align: center;
}
.privacy_policy_updated {
  font-size: 12px;
  text-align: end;
  margin-right: 5%;
}

.privacy_policy_paragraph_div {
  margin: 5%;
}

.privacy_policy_points_div > ul > li {
  font-size: 20px;
  font-weight: bold;
  margin-top: 50px;
}
.privacy_policy_points_div {
  margin: 5%;
}
